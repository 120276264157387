import React, {useState} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {withStyles} from '@material-ui/styles'
import {Paper, Button} from '@material-ui/core'
import _ from 'lodash'
import Image from 'material-ui-image'
import {Lightbox} from 'react-modal-image'
import * as Actions from './redux/actions'
import * as HeaderActions from '../common/redux/actions.header'
import styles from './styles/CourseItem.image.styles.js'

function CourseItemImage({
  classes,
  item,
  hasMinDurationPassed,
  onCourseItemComplete,
  isCompletingItem,
}) {
  const [imagePreviewOpen, setImagePreviewOpen] = useState(false)

  return (
    <div className={classes.root}>
      <div className={classes.mainCont}>
        <div className={classes.imageCont}>
          <Paper className={classes.imageBox}>
            <Image
              onClick={() => setImagePreviewOpen(true)}
              src={_.first(item?.letterSizeImage)?.image?.fullSize}
              alt="Course item"
            />
            {imagePreviewOpen && (
              <Lightbox
                large={_.first(item?.letterSizeImage)?.image?.fullSize}
                onClose={() => setImagePreviewOpen(false)}
              />
            )}
          </Paper>
        </div>
        <div className={hasMinDurationPassed ? classes.nextButton : classes.nextButtonDisabled}>
          <Button
            disabled={!hasMinDurationPassed || isCompletingItem}
            onClick={() => onCourseItemComplete(item, null)}
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  )
}

CourseItemImage.displayName = 'CourseItemImage'

function mapStateToProps(state) {
  return {
    courses: _.get(state, 'course'),
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({...Actions, ...HeaderActions}, dispatch)
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withStyles(styles, {withTheme: true}),
)(CourseItemImage)
