import React, {useEffect} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {withStyles} from '@material-ui/styles'
import {Button} from '@material-ui/core'
import _ from 'lodash'
import cx from 'classnames'
import * as Actions from './redux/actions'
import * as HeaderActions from '../common/redux/actions.header'
import styles from './styles/CourseItem.video.styles.js'

function CourseItemVideo({
  classes,
  item,
  hasMinDurationPassed,
  onCourseItemComplete,
  isCompletingItem,
}) {
  const itemValue = _.first(item?.videos)

  useEffect(() => {
    loadWistiaScript()
  }, [])

  const loadWistiaScript = () => {
    const script = document.createElement('script')

    script.src = '//fast.wistia.com/assets/external/E-v1.js'
    script.async = true

    document.body.appendChild(script)
  }

  return (
    <div className={classes.root}>
      <div className={classes.mainCont}>
        <div
          className={cx(`wistia_embed wistia_async_${itemValue?.videoId}`, classes.wistiaCont)}
        />
        <div className={hasMinDurationPassed ? classes.nextButton : classes.nextButtonDisabled}>
          <Button
            disabled={!hasMinDurationPassed || isCompletingItem}
            onClick={() => onCourseItemComplete(item, null)}
          >
            Next
          </Button>
        </div>
      </div>
    </div>
  )
}

CourseItemVideo.displayName = 'CourseItemVideo'

function mapStateToProps(state) {
  return {
    courses: _.get(state, 'course'),
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({...Actions, ...HeaderActions}, dispatch)
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withStyles(styles, {withTheme: true}),
)(CourseItemVideo)
