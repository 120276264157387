import React, {useState, useEffect} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {withStyles} from '@material-ui/styles'
import {Paper, Button, Grid} from '@material-ui/core'
import _ from 'lodash'
import cx from 'classnames'
import * as Actions from './redux/actions'
import * as HeaderActions from '../common/redux/actions.header'
import * as NotificationActions from '../common/redux/actions.notifications'
import styles, {ChoiceTypography} from './styles/CourseItem.trueFalse.styles.js'

function CourseItemTrueFalse({
  classes,
  item,
  hasMinDurationPassed,
  onCourseItemComplete,
  create,
  isCompletingItem,
  router,
}) {
  const itemValue = _.first(item?.boolean)
  const [boolean, setBoolean] = useState('')

  useEffect(() => {
    setBoolean('')
  }, [router.location.pathname])

  const handleTrue = () => {
    setBoolean(true)
  }
  const handleFalse = () => {
    setBoolean(false)
  }
  return (
    <div className={classes.root}>
      <div className={classes.mainCont}>
        <Paper className={classes.textCont}>
          {item?.image && (
            <img className={classes.textImage} src={item?.image?.fullSize} alt="text" />
          )}
          <ChoiceTypography>{itemValue?.question || ''}</ChoiceTypography>
        </Paper>
        <Grid container className={classes.ButtonCont} spacing={12}>
          <Grid item xs={false} sm={1} />

          <Button
            onClick={handleTrue}
            className={cx(classes.button, boolean ? classes.selected : null)}
          >
            True
          </Button>

          <Button
            onClick={handleFalse}
            className={cx(
              classes.falseButton,
              classes.button,
              !boolean && boolean !== '' ? classes.selected : null,
            )}
          >
            False
          </Button>

          <Grid item xs={false} sm={1} />
          <Grid item xs={12}>
            <Button
              className={
                hasMinDurationPassed
                  ? cx(classes.button, classes.nextButton)
                  : cx(classes.button, classes.nextButton, classes.buttonDisabled)
              }
              disabled={!hasMinDurationPassed || isCompletingItem}
              onClick={() => {
                if (boolean === '') {
                  create('warning', 'You must select an option to continue.')
                  return
                }
                onCourseItemComplete(item, boolean)
              }}
            >
              Next
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  )
}

CourseItemTrueFalse.displayName = 'CourseItemTrueFalse'

function mapStateToProps(state) {
  return {
    courses: _.get(state, 'course'),
  }
}

function mapDispatchToProps(dispatch) {
  return bindActionCreators({...Actions, ...HeaderActions, ...NotificationActions}, dispatch)
}

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
  withStyles(styles, {withTheme: true}),
)(CourseItemTrueFalse)
